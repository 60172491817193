import {Label} from '@radix-ui/react-label'
import {useField} from '@rvf/remix'
import {cx} from 'class-variance-authority'

export interface TextareaProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  name: string
  label: string
}

export const Textarea: React.FC<TextareaProps> = ({name, label, className, ...rest}) => {
  const field = useField<string>(name)
  const error = field.error()

  return (
    <div>
      <Label htmlFor={name} className="text-radix-mauve11 mb-2 block text-sm">
        {label}
      </Label>
      <textarea
        id={name}
        name={name}
        {...field.getInputProps({
          id: name,
          value: field.value(),
          onChange: (e) => {
            field.setValue(e.target.value)
          },
        })}
        className={cx(
          'border-radix-mauve7 bg-radix-mauve1 hover:border-radix-mauve8 active:border-radix-mauve8 block w-full rounded-sm border text-sm focus:ring-0 focus:outline-hidden',
          className,
        )}
        {...rest}
      />
      {error && <div className="text-radix-red11 mt-1 text-xs">{error}</div>}
    </div>
  )
}
