import * as Checkbox from '@radix-ui/react-checkbox'
import * as Dialog from '@radix-ui/react-dialog'
import {Label} from '@radix-ui/react-label'
import * as Tabs from '@radix-ui/react-tabs'
import type {FetcherWithComponents} from '@remix-run/react'
import {Link, useFetcher} from '@remix-run/react'
import {ValidatedForm, useFormContext} from '@rvf/remix'
import {cx} from 'class-variance-authority'
import {createContext, useCallback, useContext, useState} from 'react'
import {useParentLoaderData} from '~/hooks/useParentLoaderData'
import {useStatus} from '~/hooks/useStatus'
import {validator} from '~/routes/api.contact-us/route'
import {invariant} from '~/utils'
import {Textarea} from '../Textarea'
import {BookIcon, CheckCircleIcon, CheckIcon, DiscordIcon, FeedIcon, XIcon} from '../icons'

export type HelpTab = 'bug' | 'feedback' | 'question'

interface HelpContext {
  popoverKey: number
  isOpen: boolean
  initialTab: HelpTab
  open: (tab?: HelpTab) => void
  close: (submitted: boolean) => void
}

const HelpContext = createContext<HelpContext | null>(null)

export function useHelpContext() {
  const value = useContext(HelpContext)
  invariant(value !== null, 'useHelpContext must be a child of HelpContextProvider')
  return value
}

export function HelpContextProvider({children}: {children: React.ReactNode}) {
  const [popoverKey, setPopoverKey] = useState(0)
  const [initialTab, setInitialTab] = useState<HelpTab>('bug')
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback((tab: HelpTab = 'bug') => {
    setInitialTab(tab)
    setIsOpen(true)
  }, [])
  const close = useCallback((submitted: boolean) => {
    setIsOpen(false)
    if (submitted) setPopoverKey((key) => key + 1)
  }, [])

  return (
    <HelpContext.Provider
      value={{
        popoverKey,
        isOpen,
        open,
        close,
        initialTab,
      }}
    >
      {children}
    </HelpContext.Provider>
  )
}

export function HelpPopover() {
  const {popoverKey, isOpen, close, initialTab} = useHelpContext()

  const fetcher = useFetcher()
  const isSubmitted = Boolean(fetcher.data)
  const onClick = useCallback(() => close(isSubmitted), [close, isSubmitted])

  return (
    <Dialog.Root key={popoverKey} open={isOpen}>
      <Dialog.Portal container={typeof document !== 'undefined' ? document.body : null}>
        <Dialog.Overlay className="bg-radix-mauve1/80 fixed inset-0 z-20" suppressHydrationWarning>
          <div className="fixed inset-0" onClick={onClick}>
            <span className="sr-only">Close</span>
          </div>
        </Dialog.Overlay>
        <Dialog.Content className="border-radix-mauve6 bg-radix-mauve2 fixed top-0 right-0 bottom-0 z-50 flex w-full flex-col border-l outline-hidden sm:w-[28rem]">
          <div className="border-radix-mauve6 flex items-center justify-between gap-4 border-b p-4">
            <div className="flex-1">Contact us</div>
            <Dialog.Close asChild>
              <button
                className="border-radix-mauve7 bg-radix-mauve3 text-radix-mauve11 hover:border-radix-mauve8 hover:text-radix-mauve12 inline-flex items-center gap-1 rounded-sm border p-1 text-sm"
                onClick={onClick}
              >
                <XIcon />
                <div>Close</div>
              </button>
            </Dialog.Close>
          </div>

          {isSubmitted && <Submitted />}
          {!isSubmitted && <ContactForm fetcher={fetcher} initialTab={initialTab} />}

          <HelpLinks />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function ContactForm({fetcher, initialTab}: {fetcher: FetcherWithComponents<unknown>; initialTab: HelpTab}) {
  return (
    <Tabs.Root className="flex flex-1 flex-col space-y-4 overflow-y-scroll p-4" defaultValue={initialTab}>
      <Tabs.List className="border-radix-mauve6 grid grid-cols-3 gap-2 rounded-sm border p-1 text-sm">
        <Tabs.Trigger value="bug" className="radix-state-active:bg-radix-mauve4 rounded-sm p-1">
          Bug
        </Tabs.Trigger>
        <Tabs.Trigger value="feedback" className="radix-state-active:bg-radix-mauve4 rounded-sm p-1">
          Feedback
        </Tabs.Trigger>
        <Tabs.Trigger value="question" className="radix-state-active:bg-radix-mauve4 rounded-sm p-1">
          Question
        </Tabs.Trigger>
      </Tabs.List>

      <ValidatedForm validator={validator} fetcher={fetcher} method="post" action="/api/contact-us" className="flex-1">
        <Tabs.Content value="bug" className="flex flex-col gap-4">
          <input type="hidden" name="kind" value="bug" />
          <Textarea name="message" label="What went wrong?" rows={8} />
          <SubmitButton>Send bug report</SubmitButton>

          <div className="flex gap-2">
            <Checkbox.Root
              name="urgent"
              id="urgent"
              className="border-radix-mauve7 bg-radix-mauve1 hover:border-radix-mauve8 hover:bg-radix-mauve2 flex h-6 w-6 items-center justify-center rounded-sm border"
            >
              <Checkbox.Indicator className="flex items-center justify-center">
                <CheckIcon className="h-3 w-3" />
              </Checkbox.Indicator>
            </Checkbox.Root>
            <Label htmlFor="urgent" className="text-radix-mauve11 cursor-pointer text-sm">
              <div className="mt-1 leading-none">This is urgent</div>
              <div className="text-radix-mauve10 mt-1 max-w-xs text-xs">
                Is this bug preventing you from using Depot?
              </div>
            </Label>
          </div>
        </Tabs.Content>

        <Tabs.Content value="feedback" className="flex flex-col gap-4">
          <input type="hidden" name="kind" value="feedback" />
          <Textarea name="message" label="How can we improve Depot?" rows={8} />
          <SubmitButton>Send feedback</SubmitButton>
        </Tabs.Content>

        <Tabs.Content value="question" className="flex flex-col gap-4">
          <input type="hidden" name="kind" value="question" />
          <Textarea name="message" label="How can we help?" rows={8} />
          <SubmitButton>Send question</SubmitButton>
        </Tabs.Content>
      </ValidatedForm>
    </Tabs.Root>
  )
}

function SubmitButton({children}: {children: React.ReactNode}) {
  const {formState} = useFormContext()
  const disabled = formState.isSubmitting || !formState.isValid
  return (
    <button
      type="submit"
      className={cx(
        'border-radix-violet7 bg-radix-violet2 text-radix-violet11 hover:border-radix-violet8 block w-full rounded-sm border px-4 py-2 text-sm',
        formState.isSubmitting && 'cursor-not-allowed opacity-50',
        disabled && 'cursor-not-allowed opacity-50',
      )}
      disabled={disabled}
    >
      {formState.isSubmitting ? 'Sending...' : children}
    </button>
  )
}

function Submitted() {
  return (
    <div className="flex flex-1 flex-col gap-1 p-4">
      <div className="flex items-center gap-2">
        <CheckCircleIcon className="text-radix-grass11 h-4 w-4" />
        <div className="text-radix-mauve11">Successfully sent</div>
      </div>
      <div className="text-radix-mauve10 pl-6 text-sm">We'll get back to you as soon as possible.</div>
    </div>
  )
}

function HelpLinks() {
  const parentData = useParentLoaderData('routes/_app')
  return (
    <div className="text-radix-mauve11 space-y-4 p-4 text-sm">
      <div className="space-y-2">
        <Link
          to="/docs"
          className="border-radix-mauve7 hover:border-radix-mauve8 flex items-center gap-4 rounded-sm border px-4 py-3 leading-none"
        >
          <BookIcon className="text-radix-mauve7 h-8 w-8" />
          <div>View the documentation</div>
        </Link>

        <a
          href="https://discord.gg/MMPqYSgDCg"
          className="border-radix-mauve7 hover:border-radix-mauve8 flex items-center gap-4 rounded-sm border px-4 py-3 leading-none"
          target="_blank"
          rel="noreferrer"
        >
          <DiscordIcon className="text-radix-mauve7 h-8 w-8" />
          <div>Join the community</div>
        </a>
        <a
          href="https://status.depot.dev"
          className="border-radix-mauve7 hover:border-radix-mauve8 flex items-center gap-4 rounded-sm border px-4 py-3 leading-none"
          target="_blank"
          rel="noreferrer"
        >
          <FeedIcon className="text-radix-mauve7 h-8 w-8" />
          <div className="flex-1">Check system status</div>
          <StatusBadge />
        </a>
      </div>

      {parentData && (
        <div className="border-radix-mauve6 text-radix-mauve10 border-t pt-4 text-xs">
          You're signed in as {parentData.name}. We'll send replies to{' '}
          <span className="text-radix-mauve11">{parentData.email}</span>.
        </div>
      )}
    </div>
  )
}

function StatusBadge() {
  const status = useStatus()
  if (!status) return null

  if (status.page.status === 'UP') {
    return (
      <div className="border-radix-grass6 bg-radix-grass4 text-radix-grass11 rounded-sm border px-1 text-xs">
        online
      </div>
    )
  }

  if (status.page.status === 'HASISSUES') {
    return (
      <div className="border-radix-amber6 bg-radix-amber4 text-radix-amber11 rounded-sm border px-1 text-xs">
        has issues
      </div>
    )
  }

  if (status.page.status === 'UNDERMAINTENANCE') {
    return (
      <div className="border-radix-red6 bg-radix-red4 text-radix-red11 rounded-sm border px-1 text-xs">
        under maintenance
      </div>
    )
  }

  return null
}
